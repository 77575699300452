"use client";

import * as React from "react";
import { Button } from "@/shadcn/neoBrutalismbutton";
import { useUser } from "@clerk/nextjs";

export function SignInNeoButton() {
  const { user } = useUser();

  if (user) {
    return (
      <a href="/sign-out">
        <Button className="w-28 text-base font-heading md:text-lg lg:text-xl">
          Sign out
        </Button>
      </a>
    );
  }

  return (
    <a href="/sign-in">
      <Button className="w-28 text-base font-heading md:text-lg lg:text-xl">
        Sign in
      </Button>
    </a>
  );
}
