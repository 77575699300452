"use client";

import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

import * as React from "react";
import { Button } from "@/shadcn/neoBrutalismbutton";
import { useEffect, useState } from "react";

export function ThemeSwitcher() {
  const { setTheme, theme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <Button
        size="icon"
        className=""
        onClick={() => setTheme(theme === "light" ? "dark" : "light")}
      >
        <Sun className="hidden h-6 w-6 stroke-text w500:h-4 w500:w-4 dark:inline" />
        <Moon className="inline h-6 w-6 stroke-text w500:h-4 w500:w-4 dark:hidden" />
        <span className="sr-only">Toggle theme</span>
      </Button>
    </>
  );
}
